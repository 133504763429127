<template>

    <div>
        <v-layout pr-3 mt-1>
            <v-toolbar color="blue" height="40px" dense>
                <!-- <h1 color="#fff" style="color:#fff;font-size:18px !important;">اوقات عمل المركز</h1> -->
                <v-toolbar-title color="#fff" style="color:#fff;font-size:18px !important;">اوقات عمل المركز
                </v-toolbar-title>
                <v-divider color="#fff" class="mx-4" inset vertical></v-divider>
            </v-toolbar>
        </v-layout>


        <v-form ref="form" v-model="valid">

            <v-card height="auto" flat>



                <v-container style="padding:20px" class="additem_con">

                    <v-card class="add_card mb-12" color="#cac0c057" height="auto" flat>

                        <v-form ref="form_day" v-model="valid_day">
                            <!--Tabel Of Days-->

                            <!-- :rules="StatusTypeRules -->
                            <!-- <v-radio-group mandatory v-model="editedItem.day_to_open[0].every_day"
                                style="padding-right:15px;">
                                <v-layout row wrao pr-4>

                                    <v-flex xs2 md1 sm1>
                                        نوع الحجز

                                    </v-flex>

                                    <v-flex xs3 md2 sm2>

                                        <v-radio label="حجز موعد فتره زمنيه محدده" @change="is_everyy_day=false"
                                            :value="0"></v-radio>



                                    </v-flex>


                                    <v-flex xs7 md6 sm6>


                                        <v-radio @change="is_everyy_day=true"
                                            label="  الحجز الاني    ( عدم اختيار فتره موعد عند الحجز مفتوح كل الاسبوع)    "
                                            :value="1">
                                        </v-radio>

                                    </v-flex>



                                </v-layout>
                            </v-radio-group> -->







                            <v-layout row wrap pr-4>

                                <v-flex pr-3 pl3 md12 sm12 xs12 style="    position: relative;
    bottom: 13px;">

                                    <v-radio-group mandatory required :rules="StatusRules"
                                        v-model="editedItem.status.id">
                                        <v-layout>

                                            <v-flex xs2 md1 sm1>
                                                حاله المركز

                                            </v-flex>
                                            <v-flex xs5 md2 sm2>
                                                <v-radio label="مغلق" :value="12"></v-radio>
                                            </v-flex>

                                            <v-flex xs5 md2 sm2>
                                                <v-radio label="مفتوح" :value="11"></v-radio>

                                            </v-flex>


                                        </v-layout>





                                    </v-radio-group>


                                </v-flex>



                            </v-layout>






                            <v-flex xs12 v-if="!is_everyy_day" pr-2 pl-2 style="    position: relative;bottom: 23px;">



                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-right">اليوم</th>
                                                <!-- <th class="text-right">من الساعه</th>
                                                            <th class="text-right">الى الساعه</th> -->
                                                <!-- <th class="text-right">نوع الحجز</th> -->
                                                <th class="text-right">الحاله


                                                </th>

                                                <th class="text-right"></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr v-for="item in editedItem.day_to_open" :key="item">
                                                <td> {{ getdays(item.work_day)}} </td>

                                                <!-- <td v-if="item.time_to_open.length">
                                                                {{formatAMPM(item.time_to_open[0].from_time)}}</td>
                                                            <td v-else></td>

                                                            <td v-if="item.time_to_open.length">
                                                                {{formatAMPM(item.time_to_open[0].to_time)}}</td>
                                                            <td v-else></td> -->


                                                <!-- <td>{{getstatuss(item.reservation_type.id)}}</td> -->
                                                <td v-if="item.time_to_open.length">
                                                    {{getstatus(item.status['id'])}}



                                                </td>
                                                <td v-else>{{getstatus(item.status['id'])}}</td>

                                                <td>
                                                    <v-btn small flat color="green" style="color:#fff"
                                                        @click="editItem(item)">
                                                        <v-icon small class="mr-2">
                                                            edit
                                                        </v-icon>
                                                        <span>تعديل اوقات العمل</span>
                                                    </v-btn>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>





                                <v-dialog v-model="DayDialog" width="1100" persistent height="900">




                                    <v-card height="auto" flat>

                                        <v-layout row wrap="">
                                            <v-flex xs3 md2 sm2>
                                                <h3 style="padding-right:35px;padding-top: 20px;"> يوم
                                                    {{ getdays(editeDayOpen.work_day)}} :
                                                </h3>


                                            </v-flex>

                                            <v-flex xs2 md2 sm2>

                                                <v-form ref="valid_day_open" v-model="valid_day_open">
                                                    <span v-if="editeDayOpen.status">
                                                        <v-radio-group required :rules="DayStatusRules"
                                                            v-model="editeDayOpen.status.id">
                                                            <v-radio @change="checkEmpty()"  label="مفتوح" :value="23" ></v-radio>
                                                            <v-radio @change="time_dialog=false"  label="مغلق" :value="22"></v-radio>
                                                        </v-radio-group>
                                                    </span>
                                                </v-form>



                                            </v-flex>
                                            <v-flex xs1 md6 sm6>

                                            </v-flex>
                                            <!-- 
                                            <v-flex xs1> -->

                                            <!-- <v-spacer></v-spacer>
                                                <v-btn icon dark @click="close_day()">
                                                    <v-icon color="red">mdi-close</v-icon>
                                                </v-btn> -->
                                            <!-- 
                                            </v-flex> -->

                                            <v-flex xs5 md2 sm2 pt-3>
                                                <v-btn small dense color="green" dark @click="cancel_day()">
                                                    حفظ التعديلات وخروج

                                                    <!-- <v-icon color="red">mdi-close</v-icon> -->
                                                </v-btn>
                                            </v-flex>

                                        </v-layout>

                                        <h1 style="padding-right:35px;padding-top:10px;">
                                            <v-btn @click="time_dialog=true;xx()" color="success"
                                                style="height: 28px;margin-bottom: 9px;">

                                                <v-icon>
                                                    fas fa-plus
                                                </v-icon>

                                                اضافه فتره
                                            </v-btn>
                                        </h1>



                                        <v-layout row wrap justify="space-around" pr-4>

                                            <v-simple-table style="width:80%">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr v-if="editeDayOpen.time_to_open.length">
                                                            <!-- <th class="text-right">#</th> -->
                                                            <th class="text-right">من الساعه</th>
                                                            <th class="text-right">الى الساعه</th>
                                                            <th class="text-right">نوع الحجز</th>
                                                            <th class="text-right">عدد الحجوزات</th>
                                                            <th class="text-right">مده الحجز </th>
                                                            <th class="text-right">الحاله</th>


                                                            <th class="text-right"></th>
                                                        </tr>
                                                        <tr v-else></tr>


                                                    </thead>

                                                    <tbody>


                                                        <tr v-for="item in editeDayOpen.time_to_open" :key="item">
                                                            <!-- <td>{{item.id}} </td> -->
                                                            <td>{{formatAMPM(item.from_time)}}</td>
                                                            <td>{{formatAMPM(item.to_time)}}</td>
                                                            <td>{{item.reservation_type.reservation_type_name}}</td>
                                                            <td v-if="item.reservation_type.id==3">غير محدود</td>
                                                            <td v-else>{{item.reservations_number}}</td>
                                                            <td>{{item.reservation_duration}}</td>
                                                            <td>{{item.status.status_name_ar}}</td>
                                                            <td>

                                                                <v-btn icon
                                                                                @click="time_dialog=true,editedTimeOpen(item)">
                                                                                <v-icon>

                                                                                    edit
                                                                                </v-icon>
                                                                            </v-btn>



                                                                <v-btn icon @click="DeleteTimesOpen(item)">
                                                                    <v-icon>

                                                                        fas fa-trash
                                                                    </v-icon>

                                                                </v-btn>

                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-layout>




                                        <br>

                                        <div v-if="time_dialog ">

                                            <v-layout row wrap justify="space-around" style="padding-right:35px">




                                                <!--Add-->
                                                <v-col xs3 style="" pr-3 v-if="!is_editeTimeOpen">
                                                    <h2 style="color:#000"> من الساعه :</h2>
                                                    <v-time-picker v-model="editeTimeOpen.from_time" 
                                                        :allowed-hours="allowedHour" :max="editeTimeOpen.to_time"
                                                        locale="ar-iq">
                                                    </v-time-picker>
                                                </v-col>
  
                                                   <v-col xs3 style="" pr-3 v-else>
                                                    <h2 style="color:#000"> من الساعه :</h2>
                                                    <v-time-picker v-model="editeTimeOpen.from_time" disabled="hidden" 
                                                        :allowed-hours="allowedHour" :max="editeTimeOpen.to_time"
                                                        locale="ar-iq">
                                                    </v-time-picker>
                                                </v-col>





                                         <!--Add-->
                                                <v-col xs3 style="text-align:right" v-if="!is_editeTimeOpen">
                                                    <h2 style="color:#000">الى الساعه : </h2>
                                                    <v-time-picker :rules="to_timeRules" :min="editeTimeOpen.from_time"
                                                        locale="ar-iq" :allowed-hours="allowedHour"
                                                        v-model="editeTimeOpen.to_time" required>
                                                    </v-time-picker>
                                                </v-col>


<!--Edite-->
                                                   <v-col xs3 v-else style="text-align:right" >
                                                    <h2 style="color:#000">الى الساعه : </h2>
                                                    <v-time-picker :rules="to_timeRules" :min="editeTimeOpen.from_time" disabled="hidden"
                                                        locale="ar-iq" :allowed-hours="allowedHour"
                                                        v-model="editeTimeOpen.to_time" required>
                                                    </v-time-picker>
                                                </v-col>





  <!--Add-->
                                                <v-col>
                                                    <v-select background-color="#fff" height="50" item-value="id"
                                                        item-text="status_name_ar" :items="day_status"
                                                        placeholder="الحاله" target="#dropdown-example" return-object
                                                        v-model="editeTimeOpen.status" required
                                                        :rules="TimeStatusRules"></v-select>

                                                        <div v-if="!is_editeTimeOpen">

                                                        
                                                    <v-select background-color="#fff" height="50" item-value="id"
                                                        item-text="reservation_type_name" :items="reservation_type"
                                                        placeholder="نوع الحجز" target="#dropdown-example" return-object
                                                        @change="change_reservation_type()"
                                                        v-model="editeTimeOpen.reservation_type" required
                                                        :rules="ReservationTypeRules">
                                                    </v-select>

                                                    <v-text-field background-color="#fff"
                                                        v-if="editeTimeOpen.reservation_type.id==2"
                                                        placeholder=" اختار العدد " type="number"
                                                        v-model="editeTimeOpen.reservations_number">
                                                    </v-text-field>



                                                    <v-text-field background-color="#fff"
                                                        v-if="editeTimeOpen.reservation_type.id==1"
                                                        placeholder=" اختار عدد دقائق الحجز " type="number"
                                                        v-model="editeTimeOpen.reservation_duration">
                                                    </v-text-field>
                                                    </div>







   <!--Edite-->
                                                       <div v-else>

                                                        
                                                    <v-select disabled="hidden" background-color="#fff" height="50" item-value="id"
                                                        item-text="reservation_type_name" :items="reservation_type"
                                                        placeholder="نوع الحجز" target="#dropdown-example" return-object
                                                        @change="change_reservation_type()"
                                                        v-model="editeTimeOpen.reservation_type" required
                                                        :rules="ReservationTypeRules">
                                                    </v-select>

                                                    <v-text-field disabled="hidden" background-color="#fff"
                                                        v-if="editeTimeOpen.reservation_type.id==2"
                                                        placeholder=" اختار العدد " type="number"
                                                        v-model="editeTimeOpen.reservations_number">
                                                    </v-text-field>



                                                    <v-text-field disabled="hidden" background-color="#fff"
                                                        v-if="editeTimeOpen.reservation_type.id==1"
                                                        placeholder=" اختار عدد دقائق الحجز " type="number"
                                                        v-model="editeTimeOpen.reservation_duration">
                                                    </v-text-field>
                                                    </div>
                                                    





                                                </v-col>
                                            </v-layout>

                                            <v-row>

                                                <v-flex xs9>

                                                </v-flex>

                                                <v-flex xs3>
                                                    <v-btn color="primary" @click="save_time()"
                                                        style="text-align:left;float:left;margin-top: 4px;margin-left: 30px;">

                                                        حفظ
                                                    </v-btn>
                                                    <v-btn color="red" @click="time_dialog=false;close_time()"
                                                        style="text-align:left;float:left;margin-top: 4px;margin-left: 30px;color:#fff">

                                                        الغاء
                                                    </v-btn>
                                                    <br>

                                                </v-flex>



                                            </v-row>
                                        </div>
                                               <v-layout style="background-color:#fff">
                                                            <v-flex xs9>

                                                            </v-flex>
                                                            <v-flex xs3 pl-4>
                                                                <v-btn color="red" @click="DayDialog=false"
                                                                    style="text-align:left;float:left;color:#fff;">

                                                                    خروج
                                                                </v-btn>

                                                            </v-flex>
                                                        </v-layout>
                                    </v-card>

                                </v-dialog>







                            </v-flex>

                            <v-flex xs4>

                            </v-flex>





                        </v-form>




                        <v-layout row wrap>
                            <v-btn width="150px" color="red" to="/additem" pl-3 mr-r ml-3
                                style="color:#fff;padding-right:5px;    margin-left: 10px;">
                                <i class="fas fa-chevron-right" style=""></i>

                                <span style="padding-right:6px;"> رجوع </span>


                            </v-btn>


                            <v-btn color="primary" width="310px" @click="submit();everyy_day()">
                                تعديل
                            </v-btn>

                        </v-layout>

                    </v-card>





                    <!--End Date Info-->












                </v-container>










            </v-card>

        </v-form>

    </div>


</template>

<style>
    .without_ampm::-webkit-datetime-edit-ampm-field {
        display: none;
    }

    input[type=time]::-webkit-clear-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        -ms-appearance: none;
        appearance: none;
        margin: -10px;
    }
</style>

<script>
    const axios = require('axios');
    import moment from 'moment/src/moment'
    // import moment from 'moment';
    import {
        EventBus
    } from '../event-bus.js';



    export default {


        data: () => ({
            dialog: false,
            ss: '',
            owner: [],
            ex11: ['red', 'indigo', 'orange', 'primary', 'secondary', 'success', 'info', 'warning', 'error',
                'red darken-3', 'indigo darken-3', 'orange darken-3'
            ],
            steeps: false,
            allwayhidden: false,
            // img_url: 'http://127.0.0.1:9999',
            item_id: '',
            start: null,
            time_dialog: false,
            xc: [],
            img_name: '',
            end: null,
            state: '',
            valid_day_open: false,


            errors_address: [],
            errors_payment: [],
            day_empty:false,
            er_date: true,
            lastcategory: [],
            lastscategory: false,
            radioPayment: ["0", "1"],
            errors_date: [],
            day_status: [{
                id: 23,
                status_name_ar: 'مفتوح'
            }, {
                id: 22,
                status_name_ar: 'مغلق'
            }],
            DayDialog: false,
            msg_error: [],

            er_address: true,
            valid_ItemFeatures: false,
            valid_payment: false,

            ItemFeatures: [{
                id: '',
                item_id: '',
                feature_name: '',
                feature_price: ''

            }],



            editeItemFeatures: {
                id: '',
                item_id: '',
                feature_name: '',
                feature_price: ''
            },



            er_payment: true,

            editeTimeOpen: {

                id: '',
                action_id: '',
                from_time: '',
                reservation_duration: '',

                to_time: '',
                max_request: '',
                SubTime: [

                ],
                reservations_number: '',
                status: {
                    id: '',
                    status_name_ar: ''
                },
                reservation_type: {
                    id: '',
                    reservation_type_name: ''
                },

            },

            editeDayOpen: [{
                    id: '',
                    reservation_type: {
                        id: '',
                        reservation_type_name: ''
                    },
                    work_day: '',
                    status: {
                        id: '',
                        status_name_ar: ''
                    },
                    reservations_number: '',

                    time_to_open: [{
                            id: '',
                            action_id: '',
                            from_time: '',
                            to_time: '',
                            SubTime: [{
                                id: '',
                                time_to_open_id: '',
                                from_sub_time: '',
                                to_sub_time: '',
                                active: '',
                            }],
                            max_request: '',
                            reservations_number: '',
                            reservation_duration: '',
                            status: {
                                id: '',
                                status_name_ar: ''
                            },
                            reservation_type: {
                                id: '',
                                reservation_type_name: ''
                            },
                        },


                    ],
                }]



                ,

            er1: true,
            reservation_type: [{
                    id: 1,
                    reservation_type_name: 'اختيار فتره حجز '
                },
                {
                    id: 2,
                    reservation_type_name: 'اختيار عدد'
                },
                {
                    id: 3,
                    reservation_type_name: 'عدد غير محدود'
                }
            ],
            //    ['غير محدد','اختيار عدد'],
            e1: 1,
            switch2: false,
            radios: 'radio-1',
            submit_name: '',
            errors_primery: [],
            item_new_price: '',
            always_hidden: false,
            menu_date_from: false,
            menu_date_to: false,
            editdailog: false,
            user_info: {},
            token: '',
            err_show: false,
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
            map_dialog: false,
            modal2: false,
            book_dur_status: false,
            book_num_status: false,
            offer_info: {


            }

            ,
            reve_type: [{
                id: 0,
                duration: 'غير محدود'
            }, {
                id: 1,
                duration: 'اختيار فترة'
            }, {
                id: 2,
                duration: 'اختيار عدد لليوم الواحد'
            }],
            reve_type1: [{
                id: 0,
                duration: 'غير محدود'
            }, {
                id: 1,
                duration: 'اختيار فترة'
            }, {
                id: 2,
                duration: 'اختيار عدد  '
            }],
            error: '',
            msg_error_txt: 'هذا اليوم مكرر ',


            page: 1,
            pageCount: 0,
            items: [],
            reservation_num: false,
            editedIndex: -1,
            editedIndexday: -1,
            countries: [{
                name: 'العراق',
                id: 1
            }],
            editedIndextime: -1,

            editedIndexItemFeatures: -1,

            editedItem: {
                item_name: '',
                last_category_id: '',
                status: {
                    id: ''
                },
                id: '',
                item_description: '',
                possib_reserving_period: '',
                sub_category: {
                    category_id: 0,
                    id: 0,
                    name: "",

                },
                Address: {
                    address_descraption: '',
                    province: {
                        id: '',
                        country_id: 1,

                    },


                    long: '',
                    lat: ''

                },

                ItemFeatures: [


                ],
                images: [{
                        "image_url": ''
                    },
                    {
                        "image_url": ''
                    },
                    {
                        "image_url": ''
                    },
                    {
                        "image_url": ''
                    }
                ],

                price: {
                    id: '',
                    price_value: '',
                    payment: '',
                    deduction: 0,
                    payment_when_receiving: '',
                    free_cancellation: '',
                    payment_after_awhile: '',
                    installments: '',
                    cancellation_deduction_ratio: '0',

                },


                calories: 0,
                fat: 0,
                carbs: 0,
                disc: '',
                protein: 0,
                day_to_open: [

                    {
                        id: '',
                        work_day: '0',
                        status: {
                            id: '',
                            status_name_ar: ''
                        },

                        reservation_type: {
                            id: '',
                            reservation_type_name: ''
                        },
                        reservations_number: '',
                        time_to_open: [
                            //    {
                            //          SubTime:[
                            //                 {
                            //                     id:'',
                            //                     time_to_open_id :'',
                            //                     from_sub_time:'',
                            //                     to_sub_time:'',
                            //                     active:'',   
                            //                 }
                            //             ]
                            //    }

                        ],


                    },
                    {
                        id: '',
                        work_day: '1',
                        status: {
                            id: '',
                            status_name: ''
                        },
                        reservation_type: {
                            id: '',
                            reservation_type_name: ''
                        },
                        reservations_number: '',
                        time_to_open: [],

                    },
                    {
                        id: '',
                        work_day: '2',
                        reservation_type: {
                            id: '',
                            reservation_type_name: ''
                        },
                        status: {
                            id: '',
                            status_name_ar: ''
                        },
                        reservations_number: '',
                        time_to_open: [],


                    },
                    {
                        id: '',
                        work_day: '3',
                        reservation_type: {
                            id: '',
                            reservation_type_name: ''
                        },
                        reservations_number: '',
                        status: {
                            id: '',
                            status_name_ar: ''
                        },
                        time_to_open: [],


                    },
                    {
                        id: '',
                        work_day: '4',
                        reservation_type: {
                            id: '',
                            reservation_type_name: ''
                        },
                        reservations_number: '',
                        status: {
                            id: '',
                            status_name_ar: ''
                        },
                        time_to_open: [],


                    },
                    {
                        id: '',
                        work_day: '5',
                        reservation_type: {
                            id: '',
                            reservation_type_name: ''
                        },
                        status: {
                            id: '',
                            status_name_ar: ''
                        },
                        reservations_number: '',
                        time_to_open: [],


                    },
                    {
                        id: '',
                        work_day: '6',
                        reservation_type: {
                            id: '',
                            reservation_type_name: ''
                        },
                        reservations_number: '',
                        status: {
                            id: '',
                            status_name_ar: ''
                        },
                        time_to_open: [],


                    },
                ],

            },
            valid: false,

            deduction: '',
            days: ["السبت", "الاحد", "الاثنين", "الثلاثاء", "الاربعاء", "الخميس", "الجمعة", "كل الايام"],
            times: ["1:00 م", "2:00 م", "3:00 م", "4:00 م ", "5:00 م", "6:00 م", "7:00 م ", "8:00 م", "9:00 م",
                "10:00 م",
                "11:00 م", "12:00 م",
                "1:00 ص", "2:00 ص", "3:00 ص", "4:00 ص ", "5:00 ص", "6:00 ص", "7:00 ص ", "8:00 ص", "9:00 ص",
                "10:00 ص",
                "11:00 ص", "12:00 ص"
            ],

            img_cliced: -1,
            day_to_open: [{

                every_day: 0,
                every_time: 0,
                from_day: "",
                from_time: "",
                to_day: "",
                to_time: "",
                max_requests: 0
            }],

            nameRules: [
                v => !!v || 'هذا الحقل مطلوب  ',

            ],
            StatusRules: [
                v => !!v || 'هذا الحقل مطلوب  ',

            ],


            numberRul: [
                v => !!v || 'هذا الحقل مطلوب  ',

                v => !isNaN(v) || 'يجب ادخال رقم ',
            ],
            ReservationTypeRules: [
                v => !!v || 'هذا الحقل مطلوب  ',
                v => !isNaN(v.id) || 'يجب ادخال رقم ',

            ],


            StatusTypeRules: [
                v => !!v || 'هذا الحقل مطلوب  '


            ],
            to_timeRules: [
                v => !!v || 'هذا الحقل مطلوب  ',
                v => !isNaN(v) || 'يجب ادخال رقم ',

            ],

            TimeStatusRules: [
                v => !!v || 'هذا الحقل مطلوب  ',
                v => !isNaN(v.id) || 'يجب ادخال رقم ',

            ],

            paymentRul: [
                v => !!v || 'هذا الحقل مطلوب  ',

                v => !isNaN(v) || 'يجب ادخال رقم ',

            ],
            DayStatusRules: [
                v => !!v || 'هذا الحقل مطلوب  ',

                v => !isNaN(v) || 'يجب ادخال رقم ',

            ],


            numberRul1: [

                v => !isNaN(v) || 'يجب ادخال رقم ',
            ],
            descriptRules: [
                v => !!v || 'هذا الحقل مطلوب  ',
                v => v.length >= 20 || 'يجب ان يكون الوصف اكثر من ٢٠ حرف ',
            ],
            loading: false,
            reservation_time: false,
            province: [],
            imageName: '',
            imageUrl: '',
            is_editeTimeOpen:false,
            is_empty_editeTimeOpen:false,
            by_card: '',
            imageFile: '',
            payment: false,
            images: ['a', 'a', 'a', 'a'],
            free_cancellation: false,
            deductionvalue: 0,
            everyday: false,
            everytime: false,
            fromday: '',
            is_everyy_day: false,
            today: '',
            fromtime: '',
            totime: '',
            current_page: 1,
            SubTime: [
                // {
                //     id:'',
                //     time_to_open_id :'',
                //     from_sub_time:'',
                //     to_sub_time:'',
                //     active:'',   
                // }
            ],
            last_page: 0,
            offer_dialog: false,
            subcategory: [],
            sub_category_id: '',
            ItemStatus: [],
            category: [],
            reserv_type_id1: -1,
            valid_day: true,
            allowedHour: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
                24
            ],
            lat: 0,
            lng: 0,


        }),



        methods: {

checkEmpty()
{
    if(this.is_empty_editeTimeOpen===true)
    {
this.time_dialog=true
    }

},
            everyy_day() {
                for (var i = 0; i < 7; i++) {

                    this.editedItem.day_to_open[i].every_day = this.editedItem.day_to_open[0].every_day;

                }




            },



            close_day() {
                this.DayDialog = false;
                this.time_dialog = false;
                this.allowedHour = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
                    24
                ];

            },
            formatAMPM(date) {
                if (typeof date === "string") {
                    let [hours, minutes] = date.split(":");
                    let ampm = "ص";

                    if (Number(hours) > 12) {
                        hours = Number(hours) - 12;
                        ampm = "م";
                    }

                    return `${hours}:${minutes} ${ampm}`;

                } else if (date instanceof Date) {
                    let hours = date.getHours();
                    let minutes = date.getMinutes();

                    let ampm = hours >= 12 ? 'م' : "AM";

                    hours = hours % 12;
                    hours = hours ? hours : 12; // the hour '0' should be '12'
                    minutes = minutes < 10 ? "0" + minutes : minutes;

                    let strTime = hours + ":" + minutes + " " + ampm;

                    return strTime;
                }

                return date;
            },
            xx() {


                if (this.editeDayOpen.time_to_open.length !== 0) {
                    this.day_empty=true;
                }

                for (var j = 0; j < this.editeDayOpen.time_to_open.length; j++) {
                    var hours1 = this.editeDayOpen.time_to_open[j].from_time.split(":");
                    hours1 = parseInt(hours1[0]);
                    var hours2 = this.editeDayOpen.time_to_open[j].to_time.split(":");
                    hours2 = parseInt(hours2[0]);
                    var a = this.allowedHour.indexOf(hours1);
                    var b = this.allowedHour.indexOf(hours2);
                    var removedFruits = this.allowedHour.splice(a, b - a + 1);
                    removedFruits


                }



                // }


                this.editedIndextime = -1;
                this.editeTimeOpen = {

                    id: '',
                    action_id: '',
                    from_time: '',
                    reservation_duration: '',
                    to_time: '',
                    max_request: '',
                    SubTime: [

                    ],
                    reservations_number: '',
                    time_between: '',
                    status: {
                        id: '',
                        status_name_ar: ''
                    },
                    reservation_type: {
                        id: '',
                        reservation_type_name: ''
                    },

                }

            },
            cancel_day() {
                this.allowedHour = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
                    24
                ];
                // this.xx();
                if (this.$refs.valid_day_open.validate()) {

                    if (this.editeDayOpen.status.id == 23 && this.editedItem.day_to_open[this.editedIndexday].time_to_open
                        .length == 0  ) {



                        this.$swal('    خطاء', "يرجئ اختيار فتره حجز مفتوحه اذا كان الحجز مفتوح في هاذا اليوم", 'error')


                    } else {
                        this.editedItem.day_to_open[this.editedIndexday].status['id'] = this.editeDayOpen.status.id;
                        this.DayDialog = false;
                        this.time_dialog = false;
                        this.editeTimeOpen = {

                            id: '',
                            action_id: '',
                            from_time: '',
                            to_time: '',
                            SubTime: [

                            ],
                            reservation_duration: '',
                            max_request: '',
                            reservations_number: '',
                            status: {
                                id: '',
                                status_name_ar: ''
                            },
                            reservation_type: {
                                id: '',
                                reservation_type_name: ''
                            },

                        }

                    }



                }





            },

            close_time() {

                this.allowedHour = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
                    24
                ];

            },
            close_time_Dailog() {
                this.editedIndextime = -1;
                this.time_dialog = false;
                this.editeTimeOpen = {}
            },


            editedTimeOpen(item) {
                this.is_editeTimeOpen=true;
                this.editedIndextime = this.editeDayOpen.time_to_open.indexOf(item)
                this.editeTimeOpen = Object.assign({}, item);


            },

            DeleteTimesOpen(item) {



                this.editedIndextime = this.editeDayOpen.time_to_open.indexOf(item)
                const Swal = require('sweetalert2')
                Swal.fire({
                    title: "هل انت متاكد من حذف الفتره",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'نعم ',
                    cancelButtonText: 'خروج ',

                }).then((result) => {
                    if (result.value) {

                        for (var j = 0; j < this.editeDayOpen.time_to_open.length; j++) {
                            var hours1 = item.from_time.split(":");
                            hours1 = parseInt(hours1[0]);
                            var hours2 = item.to_time.split(":");
                            hours2 = parseInt(hours2[0]);
                            var a = this.allowedHour.indexOf(hours1) + 1;
                            var b = this.allowedHour.indexOf(hours2) + 1;

                            var removedFruits = this.allowedHour.push(a, b - a + 1);
                            removedFruits


                        }
                        this.time_dialog = false;
                        this.close_time();



                        if (item.id == 'undefined') {
                            // const index = this.editedItem.ItemFeatures.indexOf(item)
                            this.editeDayOpen.time_to_open.splice(this.editedIndextime, 1)

                        } else {
                            this.editeDayOpen.time_to_open.splice(this.editedIndextime, 1)


                            //        this.editeDayOpen.time_to_open.splice(this.editedIndextime, 1)
                            var url = "/v2/items/deleteTimeOpen/" + item.id;


                            axios({
                                method: 'delete',
                                url: url,
                                headers: {

                                }
                            }).then(response => {
                                response,


                                Swal.fire(
                                    'تم الحذف بنجاح',
                                    '',
                                    'success'
                                )

                            }).catch(error => {
                                error
                            }).finally(d => {
                                d,

                                this.getitems();
                            });



                        }

                    }
                });








            },
            SetDayStatus() {

            },

            diff_minutes(dt2, dt1) {

                var diff = (dt2.getTime() - dt1.getTime()) / 1000;
                diff /= 60;
                diff
                //  return Math.abs(Math.round(diff));


            },

            diff_duration_fun(from, to) {
                var start = moment.duration(from, "HH:mm");
                var end = moment.duration(to, "HH:mm");
                return end.subtract(start);



            },
            save_time() {

                if (this.editeTimeOpen.reservation_duration == '' &&
                    this.editeTimeOpen.reservations_number == '' && this.editeTimeOpen.reservation_type.id !== 3) {
                    this.$swal('    خطاء', " يرجئ ملئ جميع الحقول", 'error')

                }
                 else {




                    if (!this.editeTimeOpen.reservation_type.id == ' ' || !this.editeTimeOpen.reservation_type.id ==
                        null &&
                        !this.editeTimeOpen.to_time == ' ' || !this.editeTimeOpen.to_time == null
                    )

                    {
                        if (!this.editeTimeOpen.status.id == null || !this.editeTimeOpen.status.id == ' ' && !this
                            .editeTimeOpen.from_time == ' ' || !this.editeTimeOpen.from_time == null) {



                            if (this.editedIndextime > -1) {
                                console.log(this.editeTimeOpen);

                                Object.assign(this.editeDayOpen.time_to_open[this.editedIndextime], this.editeTimeOpen);
                               // Object.assign(this.editedItem.day_to_open[this.editedIndexday].time_to_open, this.editeTimeOpen)
                                
                                this.close_time_Dailog();




                            } else {

                                var hours1 = this.editeTimeOpen.from_time.split(":");
                                var hours2 = this.editeTimeOpen.to_time.split(":");
                                for (var t = parseInt(hours1); t <= parseInt(hours2); t++) {
                                  
                                    for (var a = 0; a < this.allowedHour.length; a++) {
                                        if (!this.allowedHour.includes(t)) {
                                            return this.$swal('خطاء', "الفتره موجوده لايمكن اختيارها", 'error');

                                        }



                                    }


                                }


                                if (this.editeTimeOpen.reservation_type.id == 1) {

                                    var diffs = this.diff_duration_fun(this.editeTimeOpen.from_time, this.editeTimeOpen
                                        .to_time);

                                    var diff = diffs.hours() * 60 + diffs.minutes();
                                    var duration = this.editeTimeOpen.reservation_duration;

                                    if (diff < duration) {


                                        return this.$swal('خطاء', " فتره الحجز المختاره اكبر من الوقت المتاح", 'error');

                                    }

                                    //   else
                                    //   {
                                    var duration_num = diff / duration;
                                    duration_num = parseInt(duration_num);

                                    var x = 0;
                                    var durationx = [0];
                                    for (var j = 0; j < duration_num; j++) {

                                        x = durationx.push(duration);

                                    }


                                    var f2 = 0;


                                    for (var i = 0; i < duration_num; i++) {



                                        var f1;

                                        if (f2 !== 0) {



                                            f1 = this.editeTimeOpen.from_time;
                                            f1 = f2;
                                            x = parseInt(this.editeTimeOpen.reservation_duration) + parseInt(moment
                                                .duration(f1).asMinutes());

                                            f2 = moment.utc(moment.duration(x, "minutes").asMilliseconds()).format(
                                                "HH:mm");



                                        } else {

                                            var y = moment.duration(this.editeTimeOpen.from_time).asMinutes();
                                            f1 = moment.utc(moment.duration(y, "minutes").asMilliseconds()).format(
                                                "HH:mm");
                                            this.editeTimeOpen.from_time = f1;
                                            x = parseInt(this.editeTimeOpen.reservation_duration) + moment.duration(this
                                                .editeTimeOpen.from_time).asMinutes();
                                            f2 = moment.utc(moment.duration(x, "minutes").asMilliseconds()).format(
                                                "HH:mm");

                                        }








                                        this.editeTimeOpen.reservations_number = duration;
                                        this.editeTimeOpen.reservations_number = duration_num;


                                        this.editeTimeOpen.SubTime.push({
                                            id: '',
                                            time_to_open_id: '',
                                            from_sub_time: f1,
                                            to_sub_time: f2,
                                            active: '',
                                        });


                                    }
                                    // }

                                } else if (this.editeTimeOpen.reservation_type.id == 2) {

                                    for (i = 0; i < this.editeTimeOpen.reservations_number; i++) {
                                        //this.editeTimeOpen.reservations_number = duration;
                                        // this.editeTimeOpen.reservations_number = this.editeTimeOpen.reservations_number;
                                        this.editeTimeOpen.SubTime.push({
                                            id: '',
                                            time_to_open_id: '',
                                            from_sub_time: this.editeTimeOpen.from_time,
                                            to_sub_time: this.editeTimeOpen.to_time,
                                            active: '',
                                        });


                                    }


                                } else if (this.editeTimeOpen.reservation_type.id == 3) {
                                    this.editeTimeOpen.SubTime.push({
                                        id: '',
                                        time_to_open_id: '',
                                        from_sub_time: this.editeTimeOpen.from_time,
                                        to_sub_time: this.editeTimeOpen.to_time,
                                        active: '',
                                    });

                                }





                                if (this.editeDayOpen.time_to_open.length !== 0) {
                                    this.editeDayOpen.time_to_open.length


                                }




                                this.editeDayOpen.time_to_open.push(
                                    this.editeTimeOpen

                                );
                                

                            }
                            
                             Object.assign(this.editedItem.day_to_open[this.editedIndexday].time_to_open, this.editeTimeOpen)
                                

                            //  duration+=duration


                           





                            // for(var i=0;i<=this.editedItem.day_to_open[this.editedIndexday].time_to_open.length;i++)
                            // {
                            //     if(this.editedItem.day_to_open[this.editedIndexday]['status']==6)
                            //     {
                            //         this.editedItem.day_to_open[this.editedIndexday]['status']=6;
                            //         break;


                            //     }
                            //     else
                            //     {
                            //          this.editedItem.day_to_open[this.editedIndexday]['status']=7;

                            //     }


                            // }

                            this.close_time_Dailog();





                            // this.close()







                            // }






                        } else {
                            //const swal = require('sweetalert2')
                            this.$swal('    خطاء', " يرجئ ملئ جميع الحقول", 'error')

                        }
                    } else {
                        this.$swal('    خطاء', " يرجئ ملئ جميع الحقول", 'error')
                    }
                }



            },


            tr() {
                if (this.editedItem.price.payment_when_receiving == 2) {
                    this.by_card = true;
                    this.editedItem.price.payment_when_receiving = 0;


                } else if (this.editedItem.price.payment_when_receiving == 1) {
                    this.by_card = false;

                }

            },

            editItemFeatures(item) {
                this.editedIndexItemFeatures = this.editedItem.ItemFeatures.indexOf(item)
                this.editeItemFeatures = Object.assign({}, item)
                this.ItemFeaturesDialog = true;

            },





            save_date() {
                // alert(this.editedIndextime);

                if (this.editeDayOpen.status.id == 6) {
                    if (this.editeTimeOpen.from_time == null || this.editeTimeOpen.from_time == '') {
                        alert('يجب اختيار فتره اذا كانت حاله المركز مفتوح في هاذا اليوم');
                    } else if (this.$refs.valid_day_open.validate()) {
                        Object.assign(this.editedItem.day_to_open[this.editedIndextime], this.editedTimeOpen)
                        this.time_dialog = false

                    }

                } else {
                    if (this.$refs.valid_day_open.validate()) {
                        Object.assign(this.editedItem.day_to_open[this.editedIndextime], this.editedTimeOpen)
                        this.time_dialog = false
                    }
                }

            },

            editItem(item) {
                if(item.time_to_open.length===0)
                {
                     this.is_empty_editeTimeOpen=true;
                }
                else
                {
                    this.is_empty_editeTimeOpen=false;

                }
             

                this.editedIndexday = this.editedItem.day_to_open.indexOf(item)
                /// alert(this.editedIndexday);
                //   alert(this.editedIndexday);
                this.editeDayOpen = Object.assign({}, item)
                this.DayDialog = true
                if (item.reservation_type.id == 2) {
                    this.reservation_num = true;
                } else {
                    this.reservation_num = false;

                }
            },



            getdays(index) {
                var days = ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"];

                return days[index];
            },

            getstatus(index) {
                if (index == 22) {
                    return 'مغلق';
                } else if (index == 23) {
                    return 'مفتوح';

                }


            },
            getstatuss(index) {
                if (index == 1) {
                    return 'غير محدد';
                } else if (index == 2) {
                    return 'اختيار عدد';

                }


            },
            getItemDetails() {

                this.user_info = this.$cookies.get('admin_info');


                var url = "/v2/items/owner/OwnerItemsById/" + this.$route.params.id;
                this.$http({
                        method: 'get',
                        url: url,
                        headers: {

                        }
                    })

                    .then(response => {

                        this.editedItem = response.data.data;

                        if (this.editedItem.day_to_open[0].every_day)

                        {
                            this.is_everyy_day = true;
                        }


                    })
            },





            cancel() {
                this.$refs.form.reset(),
                    this.$emit("changeStatus", false);
            },

            editt() {
                // alert(this.item_id);
                this.$emit("edite_item");

            },







            Check_date() {



                if (!this.$refs.form_day.validate()) {
                    this.er_date = false,
                        this.errors_payment.push("يرجئ ملئ الحقول");
                } else {
                    this.er_date = true;
                }
                // this.errors_dates = [];
                // if (this.everyday) {
                //     alert(this.everyday);
                // }

                // if (this.day_to_open.length == 0) {

                //     this.errors_date.push("يرجئ تحديد الوقت");
                // } else if (this.everyday) {

                //     this.er_date = true;
                // } else {
                //     this.er_date = false;
                // }

                // if (this.errors_date.length) {
                //     this.er_date = false;
                // } else if (this.everyday) {
                //     alert(this.everyday);
                //     this.er_date = true;
                // } else {
                //     this.er_date = false;
                // }
            },


















            change_timeday() {
                this.day_to_open.length = 0;
            },
            select_reser_type1(id) {

                this.reserv_type_id1 = id;
            },


            allowedHours(v) {
                v

                return false;
            },

            comput_maxRequest(id, time_duration, from_tim, to_tim) {

                if (id == 0) {
                    return 0;
                } else if (id == 1) {
                    // this.reservation_duration

                    var from_hour = parseInt(from_tim.split(":")[0])
                    var from_min = parseInt(from_tim.split(":")[1])


                    var to_hour = parseInt(to_tim.split(":")[0])
                    var to_min = parseInt(to_tim.split(":")[1])

                    var from = from_hour * 60 + from_min;


                    var to = to_hour * 60 + to_min;

                    var result = to - from;

                    var houer = parseInt(time_duration.split(":")[0]);
                    var minit = parseInt(time_duration.split(":")[1]);

                    var dur = houer * 60 + minit;

                    result = result / dur

                    return result

                } else {
                    return 0
                }




            },
            submit() {



                if (this.editedItem.status.id == 11 && this.editedItem.day_to_open[0].every_day==0) {
                    var day;
                    day = this.editedItem.day_to_open;
                    if (day.some(day => day.status.id === 23)) {
                        day


                    } else {
                        day
                        return this.$swal('  تحذير', "  لايوجد يوم مفتوح لايمكن اختيار حاله المركز مفتوح", 'warning');
                    }
                    day
                }








                if (this.DayOpen == 0) {
                    this.$swal('تحذير', "    يجب اختيار جدول الحجز", 'warning')
                }



                this.user_info = this.$cookies.get('admin_info');


                if (this.$refs.form.validate()) {







                    var data = {



                        work_times: this.editedItem.day_to_open,
                        status_id: this.editedItem.status.id,








                    };


                    this.loading = true;
                    var posturl = "/v2/items/owner/UpdateDayOpenByItemId/" + parseInt(this.$route.params.id);

                    //  var posturl = "/v2/items/store";



                    data,
                    this.$http({
                            method: 'post',
                            url: posturl,
                            data: data,
                            headers: {


                            }

                        })
                        .then(response => {
                            this.$router.push({
                                name: "additem"
                            })


                            this.$swal('    تم', "   تم تعديل اوقات العمل",
                                'success')




                            this.dialog = false;
                            EventBus.$emit("changeStatus", false);

                            if (response.data.success == true) {

                                this.dialog = false;


                            }




                        })
                        .catch((error) => {
                            error

                            if (this.$route.params.id) {
                                this.$swal('    ', "   فشل تعديل معلومات الحجز",
                                    'error')

                            } else {
                                this.$swal('', "فشل اضافة الحجز", 'error')
                            }


                            // this.$swal('خطاء', this.item_id != 0 ? "     فشل  اضافة الحجز" : "فشل تعديل الحجز",
                            //     'error')

                        }).finally(d => {
                            d,
                            this.loading = false;
                            this.everyday = false;
                            this.everytime = false;
                        });
                    // } else {

                    //     this.err_show = true;




                    //  }





                }




            },




















            change_reservation_type() {


                if (this.editeDayOpen.reservation_type.id == 2) {
                    this.reservation_num = true;
                } else {
                    this.reservation_num = false;
                    this.reservation_time = true;

                }

            },






            save() {
                if (this.editedIndex > -1) {
                    Object.assign(this.editedItem.day_to_open[this.editedIndex], this.editedItem)
                } else {
                    this.editedItem.day_to_open.push(this.editedItem)
                }
                this.close()
            },


        },

        watch: {
            selected: 'dd',
            steps(val) {

                if (this.e1 > val) {
                    this.e1 = val
                }
            },



        },
        computed: {


        },




        created() {



        },


        mounted() {

            this.user_info = this.$cookies.get('admin_info');


            this.getItemDetails()


        },

        components: {



        }

    }
</script>